const recaptcha = require('google_recaptcha/components/recaptcha');
const createErrorNotification = require('../components/errorNotification');
const formValidation = require('../components/formValidation');

module.exports = {

    resetPassword() {
        $('.reset-password-form').submit(function (e) {
            const form = $(this);
            e.preventDefault();
            const url = form.attr('action');
            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success(data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $('.request-password-title').text(data.receivedMsgHeading);
                        $('.request-password-body').empty()
                            .append(`<p>${data.receivedMsgBody}</p>`);
                        if (!data.mobile) {
                            $('#submitEmailButton').text(data.buttonText)
                                .attr('data-dismiss', 'modal');
                        } else {
                            $('.send-email-btn').empty()
                                .html(`<a href="${data.returnUrl
                                    }" class="btn btn-primary btn-block">${data.buttonText}</a>`);
                        }
                    }
                },
                error() {
                    form.spinner().stop();
                },
            });
            return false;
        });
    },

    clearResetForm() {
        $('#login .modal').on('hidden.bs.modal', () => {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    },

    loginSubmit: () => {
        $('form.login').submit(function (e) {
            const form = $(this);
            e.preventDefault();
            const url = form.attr('action');
            form.spinner().start();
            $('form.login').trigger('login:submit', e);
            $.ajax({
                url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: (data) => {
                    form.spinner().stop();
                    if (!data.success) {
                        createErrorNotification(form, data.error.join('<br/>'));
                        $('form.login').trigger('login:error', data);
                    } else {
                        $('form.login').trigger('login:success', data);
                        window.location.href = data.redirectUrl; //eslint-disable-line
                    }
                },
                error: (data) => {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                        form.spinner().stop();
                    }
                },
            });
            return false;
        });
    },

    submit: () => {
        const $form = $('.registration');

        $form.on('submit', (e) => {
            const submitForm = (token) => {
                if (token) {
                    $('input[name=g-recaptcha-response]').val(token);
                }
                const url = $form.attr('action');
                $form.spinner().start();
                $('form.registration').trigger('login:register', e);

                const isOptForSms = $form.find(`input[name='joinOptInMessage']`).is(':checked');
                const isOptForEmail = $form.find(`input[name='joinOptInEmail']`).is(':checked');
                const isOptForNotification = isOptForSms || isOptForEmail;

                $.ajax({
                    url,
                    type: 'post',
                    dataType: 'json',
                    data: $form.serialize(),
                    success(data) {
                        $form.spinner().stop();
                        if (!data.success) {
                            $('form.registration').trigger('login:register:error', data);
                            formValidation($form, data);
                        } else {
                            $('form.registration').trigger('login:register:success', data);
                            if (isOptForNotification) {
                                window.localStorage.setItem('joining', true);
                            }
                            document.cookie = 'hide_accountpage_popup=; Max-Age=-99999999; path=/';
                            window.location.href = data.redirectUrl;
                        }
                    },
                    error(err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                        }

                        $form.spinner().stop();
                    },
                });
                return false;
            };
            const recaptchaKey = $('.js-recaptcha').data('sitekey');

            e.preventDefault();

            if (recaptchaKey) {
                window.grecaptcha.ready(() => {
                    window.grecaptcha.execute(recaptchaKey, { action: 'submit' }).then((token) => {
                        submitForm(token);
                    });
                });
            } else {
                submitForm();
            }
        });
    },
    recaptchaInit: recaptcha.init,

};