"use strict";

var $cache = {};

/**
 * @private
 * @function
 * @description Handle success callback
 * @param {Object} button  G-Captcha button
 */
function onSuccess(button) {
    var $button = $(button.element);
    var $currentForm = $button.parents("form");
    var $submitBtn = $currentForm.find(":submit");

    $button.data("gcaptcha-valid", true);
    var callback = $button.data("gcaptcha-callback");

    $submitBtn.prop("disabled", false);

    if (callback) {
        callback();
    }
}

/**
 * @private
 * @function
 * @description Registers on load callback
 */
function registerCallback() {
    window.reCaptchaLoad = render;
}

/**
 * @private
 * @function
 * @description Adds recaptcha script to body
 */
function addScript() {
    let script = document.createElement("script");
    let $recaptcha = $(".js-recaptcha");
    let src = $recaptcha.data("clienturl");

    if ($recaptcha.data("version") == "3") {
        src += `?render=${$recaptcha.data("sitekey")}`;
    } else if ($recaptcha.data("version") == "2") {
        src += "?onload=reCaptchaLoad&render=explicit";
    }

    script.src = src;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
}

/**
 * @private
 * @function
 * @description Renders recaptcha button
 */
function render() {
    $.each($cache.capchaButtons, function (i, btn) {
        var gkey = $(btn.element).data("sitekey");

        if (gkey && $(btn.element).data("active") && !$(btn.element).data("loaded")) {
            btn.id = window.grecaptcha.render(btn.element, { 
                sitekey: gkey,
                callback: function () {
                    onSuccess(btn);
                }
            });
            configureExecution(btn);
        }
    });
}

/**
 * @private
 * @function
 * @description Configures execution
 * @param {Object} btn Object with gcaptcha button and its id
 */
function configureExecution(btn) {
    var $form = $(btn.element).closest("form");

    // Set manual execution function on form data attribute
    if ($(btn.element).data("gcaptcha-execute")) {
        $form.data("gcaptcha-execute", function (event) {
            executeCaptcha(event, btn.id);
        });
    } else {
        $form.submit(function (event) {
            btn.$submitBtn = $form.find("button[type=submit]:focus");

            if (!$(btn.element).data("gcaptcha-valid")) {
                executeCaptcha(event, btn.id);
            }
        });
    }
    $(btn.element).data("loaded", true);
}

/**
 * @private
 * @function
 * @description Executes recaptcha
 * @param {Object} btn G-captcha button
 * @param {String} id Id of the g-button
 */
function executeCaptcha(event, id) {
    if (event && event.preventDefault) {
        event.preventDefault();
    }
    window.grecaptcha.reset(id);
}

/**
 * @private
 * @function
 * @description Initializes cache
 */
function initializeCache() {
    $cache.capchaButtons = $(".js-recaptcha").map(function (i, el) {
        return {
            element: el
        };
    });
}

/**
 * @private
 * @function
 * @description Initializes events
 */
function initializeEvents() {
    initializeCache();
    registerCallback();
    addScript();
}

/**
 * @function
 * @description Initializes module
 */
module.exports = {
    init: function () {
        initializeEvents();
    },
    reload: function () {
        initializeCache();
        render();
    }
};
